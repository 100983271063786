<template>
  <v-form
    class="w-100"
    ref="addNewAddressForm"
    v-model="isAddressFormValid"
    @submit.prevent="submitAddressForm"
  >
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          :items="departmentsList"
          :disabled="!!forcedDepartment"
          label="Oddział"
          v-model="address.departmentId"
          placeholder="Wybierz oddział lokalizacji"
          v-bind="autocompleteAttrs"
        />
      </v-col>

      <v-col
        cols="12"
        class="pb-0"
      >
        <gmap-autocomplete
          class="introInput"
          @place_changed="getAddressData"
          :options="autocompleteOptions"
          select-first-on-enter
        >
          <template #input="slotProps">
            <v-text-field
              outlined
              prepend-inner-icon="place"
              placeholder="Wyszukaj adres"
              label="Adres lokalizacji"
              :value="address.address.formattedAddress"
              hide-details
              ref="input"
              @listeners="slotProps.listeners"
              @attrs="slotProps.attrs"
            >
              <template #prepend-inner>
                <Icon
                  name="search"
                  size="small"
                  class="mr-2"
                  @click="showProps(slotProps)"
                />
              </template>
            </v-text-field>
          </template>
        </gmap-autocomplete>
      </v-col>

      <v-col
        cols="12"
        class="d-flex align-start py-0"
      >
        <v-input
          :value="choosenAddress"
          :rules="[rules.required]"
          hide-details
          class="validated-label"
        >
          <div class="mr-1">
            Wybrany adres:
          </div>
          <span class="font-weight-medium">{{ choosenAddress || '-' }}</span>
        </v-input>
      </v-col>

      <v-col
        cols="12"
        class="pt-0"
      >
        <v-checkbox
          v-model="showOwnNameField"
          label="Zmień nazwę lokalizacji"
          hide-details
          small
          class="mt-0 pt-0"
          :class="{'pb-2' : !showOwnNameField}"
        />
      </v-col>

      <v-expand-transition>
        <v-col
          cols="12"
          v-if="showOwnNameField"
          class="pt-2"
        >
          <v-text-field
            outlined
            label="Nazwa lokalizacji"
            v-model="address.name"
            validate-on-blur
            placeholder="Wpisz własną nazwę"
            class="mt-2"
          />
        </v-col>
      </v-expand-transition>

      <v-col
        cols="12"
        class="pb-0"
      >
        <v-autocomplete
          v-model="address.communeId"
          :items="communes"
          label="Gmina"
          placeholder="Wybierz gminę"
          v-bind="autocompleteAttrs"
          :item-text="(item)=> `${item.name} ${item.communeType ? '('+item.communeType+')' : ''}`"
          :rules="[]"
          hide-details
          clearable
        />
      </v-col>

      <v-col
        cols="12"
        class="pt-0"
      >
        <div class="d-flex align-center">
          <span class="input-label">
            Dodaj gminę
          </span>

          <v-switch
            v-model="showCommuneForm"
            hide-details
            class="ml-2 my-0 pa-0"
          />
        </div>
        <v-expand-transition>
          <AddCommune
            v-if="showCommuneForm"
            small
            @communeAdded="updateCommune"
          />
        </v-expand-transition>
      </v-col>
      <v-col
        cols="12"
        v-if="!editingAddress"
      >
        <v-text-field
          outlined
          label="Osoba kontaktowa"
          v-model="address.contactName"
          placeholder="Wpisz imię i nazwisko osoby do kontaktu"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Telefon"
          v-model="address.phoneNumber"
          :rules="[rules.phoneNumber]"
          v-mask="getFieldMask('phoneNumber')"
          placeholder="Wpisz numer telefonu"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Email"
          v-model="address.email"
          :rules="[rules.email]"
          validate-on-blur
          placeholder="Wpisz adres email"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          label="Wysokość rabatu [zł]"
          v-model.number="address.discount"
          type="number"
          :rules="[rules.money, rules.lowerThan(10000001)]"
          validate-on-blur
          placeholder="0zł"
          hide-details
        />
      </v-col>
      <v-col
        cols="8"
        class="pb-0"
      >
        <v-text-field
          outlined
          label="Uzasadnienie rabatu"
          v-model="address.discountJustification"
          placeholder="Wpisz uzasadnienie rabatu"
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        class="py-0 mt-4"
      >
        <PaymentType
          :payment-data="address"
          not-required
          @update="updatePaymentType"
        />
      </v-col>

      <v-col cols="12">
        <v-textarea
          outlined
          :rows="2"
          auto-grow
          label="Notatka do lokalizacji"
          v-model="address.notes"
          placeholder="Wpisz notatkę do adresu"
        />
      </v-col>

      <v-col cols="12">
        <v-textarea
          outlined
          :rows="2"
          auto-grow
          label="Uwagi dla spedytora"
          v-model="address.remarks"
          placeholder="Wpisz uwagi"
        />
      </v-col>

      <v-col
        v-if="!editingAddress && clientType !== 'Osoba fizyczna'"
        cols="12"
        class="py-0"
      >
        <v-row
          v-for="(product, index) in address.products"
          :key="'product'+index"
        >
          <v-col cols="3">
            <v-autocomplete
              :items="containerTypes"
              label="Typ kontenera"
              v-model="address.products[index].containerTypeId"
              placeholder="Typ kontenera"
              v-bind="autocompleteAttrs"
            />
          </v-col>

          <v-col cols="5">
            <v-autocomplete
              :items="debrisAndAggregateTypes"
              label="Typ odpadu/kruszywa"
              v-model="address.products[index].debrisTypeId"
              placeholder="Typ odpadu/kruszywa"
              v-bind="autocompleteAttrs"
              :filter="searchDebrisType"
              :item-text="getDebrisString"
              multiple
            >
              <template #selection="{ item, index:number }">
                <MultiselectChoices
                  :index="number"
                  :label="item.code"
                  :array-length="address.products[index].debrisTypeId.length"
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              type="number"
              label="Cena netto"
              :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
              v-model.number="address.products[index].netValue"
              placeholder="Wpisz cenę [zł]"
            />
          </v-col>

          <v-col
            cols="1"
            class="pl-0"
          >
            <v-btn
              icon
              @click="removeProduct(index)"
            >
              <img
                width="24"
                src="@/assets/icons/block-mark.svg"
                alt="blocked"
              >
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          class="mt-n4"
          color="primary"
          text
          @click="addProduct"
        >
          Dodaj produkt
        </v-btn>
      </v-col>

      <v-flex v-if="actions">
        <v-btn
          color="black"
          text
          small
          @click="$emit('close')"
        >
          Anuluj
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          text
          small
        >
          Zapisz lokalizację
        </v-btn>
      </v-flex>
    </v-row>
  </v-form>
</template>

<script>
import AddCommune from './AddCommune'
import PaymentType from './PaymentType'
import MultiselectChoices from '../../Elements/MultiselectChoices'
import rules from '../../../utils/validators'
import { Address } from '../../../models'
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit, getFieldMask, searchDebrisType, getDebrisString, formatChangedPhoneNumber } from '../../../utils'
import set from 'lodash/set'

export default {
  props: {
    actions: {
      type: Boolean,
      default: false
    },
    forcedDepartment: {
      type: [String, Number],
      required: false
    },
    editingAddress: {
      type: Object,
      required: false
    }
  },
  components: {
    AddCommune,
    PaymentType,
    MultiselectChoices,
  },
  data () {
    return {
      rules,
      isAddressFormValid: true,
      showCommuneForm: false,
      address: new Address(),
      showOwnNameField: false,
      autocompleteOptions: {
        fields: ['formatted_address', 'geometry.location'],
        componentRestrictions: {
          country: ['pl']
        }
      },
      autocompleteAttrs: {
        itemText: 'name',
        itemValue: 'id',
        outlined: true,
        dense: true,
        rules: [rules.required]
      }
    }
  },
  watch: {
    'address.phoneNumber' (value) {
      if (value && !value.includes('-') && value.length === 9) {
        this.$nextTick(() => {
          this.address.phoneNumber = '' + this.formatChangedPhoneNumber(value)
        })
      }
    }
  },
  computed: {
    ...mapState({
      departmentsList: state => state.departments.items,
      containerTypes: state => state.containerTypes.items,
      debrisTypes: state => state.debrisTypes.items,
      aggregateTypes: state => state.aggregateTypes.items,
      communes: state => state.communes.items,
      filters: state => state.core.filters,
      clientType: state => state.client.entity.clientType,
    }),
    debrisAndAggregateTypes () {
      return [...this.debrisTypes, ...this.aggregateTypes]
        .sort((a, b) => a.name?.localeCompare(b.name))
    },
    choosenAddress () {
      const { lat, lng, formattedAddress } = this.address.address
      return lat && lng && formattedAddress
    }
  },
  created () {
    if (this.editingAddress) {
      const { name, formattedAddress } = this.editingAddress
      this.showOwnNameField = name && name !== formattedAddress
      const baseAddress = { ...this.editingAddress }
      baseAddress.discount = baseAddress.discount / 100
      this.address = new Address(baseAddress)
    }
  },
  mounted () {
    if (this.forcedDepartment) this.address.departmentId = this.forcedDepartment
    this.getCommunes()
    if (!this.editingAddress) {
      this.getContainerTypes()
      this.getDebrisTypes({ params: { aggregate: false } })
      this.getAggregateTypes({ params: { endpoint: 'debrisTypes', aggregate: true } })
    }
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      getDebrisTypes: 'debrisTypes/getItems',
      getAggregateTypes: 'aggregateTypes/getItems',
      getCommunes: 'communes/getItems',
    }),
    getFieldMask,
    searchDebrisType,
    getDebrisString,
    formatChangedPhoneNumber,
    submitAddressForm () {
      this.$refs.addNewAddressForm.validate()
      if (this.isAddressFormValid) {
        const { discount, products, ...addressParams } = this.address
        if (!this.showOwnNameField) addressParams.name = addressParams.address.formattedAddress
        const parsedProducts = products.map(product => {
          product.netValue = parseAsBasicUnit(product.netValue)
          if (Array.isArray(product.debrisTypeId)) {
            return product.debrisTypeId.map(debrisTypeId => ({ ...product, debrisTypeId }))
          }
          return product
        }).flat()
        const address = {
          ...addressParams,
          discount: parseAsBasicUnit(discount),
          products: parsedProducts
        }
        if (this.editingAddress) {
          this.$emit('editAddress', address)
        } else {
          this.$emit('addNewAddress', address)
        }
      }
    },
    getAddressData (e) {
      this.address.address = {
        formattedAddress: e.formatted_address,
        lat: e.geometry.location.lat().toString(),
        lng: e.geometry.location.lng().toString()
      }
      this.address.name = e.formatted_address
    },
    copyClientData (data) {
      this.address = {
        ...this.address,
        ...data
      }
    },
    addProduct () {
      this.address.products.push(
        { debrisTypeId: null, containerTypeId: null, netValue: null }
      )
    },
    removeProduct (index) {
      this.address.products.splice(index, 1)
    },
    updateCommune (id) {
      this.address.communeId = id
      this.showCommuneForm = false
    },
    updatePaymentType (data) {
      const key = data?.key?.split('.').pop() // key is 'payment.label'
      set(this.address, key, data.value)
    }
  }
}
</script>
