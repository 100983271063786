<template>
  <div>
    <span v-if="index < visibleItems">{{ label }}</span>
    <span
      v-if="index < arrayLength - 1 && index < (visibleItems - 1)"
      class="mr-1"
    >,</span>
    <span
      v-if="index === visibleItems"
      class="ml-1"
    >
      (+{{ arrayLength - visibleItems }})
    </span>
  </div>
</template>

<script>
export default {
  props: {
    visibleItems: {
      type: Number,
      default: 1,
    },
    index: {
      type: Number,
      required: true,
    },
    arrayLength: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
  }
}
</script>
